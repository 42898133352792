import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';

import { HttpGet } from 'ibis-design-system/lib/HelperFunctions/HttpHelper';
import LoginProvider from 'ibis-design-system/lib/Providers/LoginProvider';
import LoadScreen from 'ibis-design-system/lib/components/core/LoadScreen';
import ThemeProvider from 'ibis-design-system/lib/ThemeProvider';

import * as serviceWorker from './serviceWorker';
import Settings, { SetSettings } from './Settings';

const AppContainer = lazy(() => import('./AppContainer'));

let settingsPath = 'appSettings.json';
let isDevelopment = process.env.NODE_ENV === 'development';
if (isDevelopment === true) settingsPath = 'appSettings.dev.json';

const render = function() {
    ReactDOM.render(
        <ThemeProvider>
            <LoginProvider
                api={Settings.apiUrls.portal}
                ssoApi={Settings.apiUrls.singleSignOn}
                sso={Settings.uiUrls.singleSignOn}
                returnUrl={window.location.href}
            >
                <Suspense
                    fallback={
                        <div style={{ height: '100vh' }}>
                            <LoadScreen />
                        </div>
                    }
                >
                    <AppContainer />
                </Suspense>
            </LoginProvider>
        </ThemeProvider>,
        document.getElementById('root')
    );
};

HttpGet(`${window.location.origin}/${settingsPath}`).then(response => {
    SetSettings(response);
    render();
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
